import React, { Fragment } from "react";
import Media from "react-media";

import StyledLink from "../../components/styled-link/StyledLink";

import footerStyle from "./assets/Footer.module.scss";

const Footer = () => {
  const date = new Date();
  const currentYear = date.getFullYear();

  const copyright = <span>© 2012 - {currentYear} Svatební salon Delta</span>;
  const contactMost = <span>+420 476 102 617</span>;
  const contactChomutov = <span>Chomutov: +420 474 331 144</span>;
  const email = (
    <StyledLink href="mailto:vyoralova@svatby-most.cz">
      vyoralova@svatby-most.cz
    </StyledLink>
  );
  const social = (
    <Fragment>
      <StyledLink
        href="https://www.facebook.com/SvatebnisalonDelta/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Facebook
      </StyledLink>
      <span>&nbsp;&&nbsp;</span>
      <StyledLink
        href="https://www.instagram.com/svatebnisalondelta.official/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </StyledLink>
    </Fragment>
  );
  const author = (
    <StyledLink
      href="https://bartosjiri.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      BJ
    </StyledLink>
  );

  const mobileFooter = (
    <div className={footerStyle["footer_mobile"]}>
      <div className={footerStyle["footer_mobile_item"]}>{social}</div>
      <div className={footerStyle["footer_mobile_item"]}>{contactMost}</div>
      <div className={footerStyle["footer_mobile_item"]}>{email}</div>
      <div className={footerStyle["footer_mobile_item"]}>{copyright}</div>
      <div className={footerStyle["footer_mobile_item"]}>{author}</div>
    </div>
  );

  const desktopFooter = (
    <div className={footerStyle["footer"]}>
      <div className={footerStyle["footer_item"]}>{copyright}</div>
      <div className={footerStyle["footer_item"]}>{contactMost}</div>
      <div className={footerStyle["footer_item"]}>{email}</div>
      <div className={footerStyle["footer_item"]}>{social}</div>
      <div className={footerStyle["footer_item"]}>{author}</div>
    </div>
  );

  return (
    <footer>
      <Media query="(max-width: 768px)" defaultMatches={false}>
        {matches => (matches ? mobileFooter : desktopFooter)}
      </Media>
    </footer>
  );
};

export default Footer;
